/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible Flow Type values
 * @export
 */
export const SearchFlowTypeEnum = {
    INDIRECT: '00',
    DIRECT: '01'
} as const;
export type SearchFlowTypeEnum = typeof SearchFlowTypeEnum[keyof typeof SearchFlowTypeEnum];


export function instanceOfSearchFlowTypeEnum(value: any): boolean {
    for (const key in SearchFlowTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(SearchFlowTypeEnum, key)) {
            if (SearchFlowTypeEnum[key as keyof typeof SearchFlowTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchFlowTypeEnumFromJSON(json: any): SearchFlowTypeEnum {
    return SearchFlowTypeEnumFromJSONTyped(json, false);
}

export function SearchFlowTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFlowTypeEnum {
    return json as SearchFlowTypeEnum;
}

export function SearchFlowTypeEnumToJSON(value?: SearchFlowTypeEnum | null): any {
    return value as any;
}

export function SearchFlowTypeEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): SearchFlowTypeEnum {
    return value as SearchFlowTypeEnum;
}

