import { MessageDescriptor } from 'react-intl';
import cm from 'core/commonMessages';
import { Privilege } from 'core/redux/userApi';
import { useMemo } from 'react';
import { usePrivilegesQuery } from 'hooks/privilege';
import { KB_MOD } from 'core/util/keyboard';
import { useRouteBuCode } from 'hooks/buCode';
import { useGetMetadataQuery } from 'core/redux/storeMetadataApi';
import { useBuCodeIntegration } from 'components/BuCodeIntegration';

type MenuItemConfiguration = {
  message: MessageDescriptor;
  url: string;
  shortcut: [string, string];
  privilege?: Privilege;
  needsCloud?: boolean;
};

export type MenuItem = MenuItemConfiguration & {
  isAuthorized: boolean;
};

const MENU_ITEMS: MenuItemConfiguration[] = [
  {
    message: cm.maintainTitle,
    url: '/maintain',
    shortcut: [KB_MOD, '1'],
  },
  {
    message: cm.storeStructureTitle,
    url: '/structure/division-speciality-shop',
    shortcut: [KB_MOD, '2'],
    privilege: Privilege.READ_STORE_STRUCTURE,
  },
  {
    message: cm.parametersTitle,
    url: '/parameters/default',
    shortcut: [KB_MOD, '4'],
  },
  {
    message: cm.summariesTitle,
    url: '/summaries/time-view',
    shortcut: [KB_MOD, '6'],
    privilege: Privilege.READ_REPORTS,
  },
  {
    message: cm.importTitle,
    url: '/import',
    shortcut: [KB_MOD, '7'],
    privilege: Privilege.BULK_UPDATE_ITEM_ASSIGNED_SALES_LOCATION,
    needsCloud: true,
  },
  /*{
    message: cm.serviceOfficeTitle,
    url: '/',
    shortcut: [KB_MOD, '8'],
  },
  {
    message: cm.userManagementTitle,
    url: '/',
    shortcut: [KB_MOD, '9'],
  },*/
  {
    message: cm.devToolsTitle,
    url: '/devtools',
    shortcut: [KB_MOD, '0'],
    privilege: Privilege.DEVELOPER,
  },
];

export function useMenuItems(): MenuItem[] {
  const { data: privileges } = usePrivilegesQuery();
  const { buCode, isSuccess } = useBuCodeIntegration();
  const { data } = useGetMetadataQuery(buCode, { skip: !isSuccess });

  return useMemo(() => {
    return MENU_ITEMS.map(item => {
      return {
        ...item,
        isAuthorized:
          (item.privilege === undefined ||
            (privileges ?? []).includes(item.privilege)) &&
          !(item.needsCloud && !(data && data.isCloudMaster)),
      };
    });
  }, [data, privileges]);
}

export function keyboardShortcut({ shortcut }: MenuItem) {
  return `${shortcut[0]} + ${shortcut[1]}`;
}
