import React, { useEffect, useMemo } from 'react';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';
import { HideModal, useDialogActions } from 'hooks/modal';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Button from '@ingka/button';
import './WeekAssignmentsModal.scss';
import InputField from '@ingka/input-field';
import mm from 'views/Maintain/maintainMessages';
import Checkbox from '@ingka/checkbox';
import WeekDay from 'components/Date/WeekDay';
import { useForm, UseFormWatch } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { updatedDiff } from 'deep-object-diff';
import { MerchandisingSolution } from 'apis/backendApi';
import { DayAssignment, WeekAssignments } from 'views/Maintain/types';
import SkapaSelect from 'components/SkapaSelect';
import { useMerchandisingTerms } from 'views/Maintain/hooks/language';

type FormValues = Record<string, DayAssignment>;

const DayAssignmentColumn: React.FC<{
  register: UseFormRegister<FormValues>;
  watch: UseFormWatch<FormValues>;
  date: string;
  readOnly: boolean;
}> = ({ date, register, watch, readOnly }) => {
  const isModified = watch(`${date}.isModified`);
  const merchText = useMerchandisingTerms('normal');
  const numericValidation = {
    isNumberInRange: (value: number, formValues: FormValues) => {
      return (
        !formValues[date].isModified ||
        (value !== null && typeof value === 'number' && value >= 0)
      );
    },
    isInteger: (value: number, formValues: FormValues) =>
      !formValues[date].isModified ||
      (typeof value === 'number' && Number.isSafeInteger(value)),
  };
  return (
    <div className="slm-day-assignment">
      <label>
        <WeekDay value={date} /> {isModified ? '*' : ''}
      </label>
      <InputField
        id={`quantity_${date}`}
        type="text"
        autoComplete="off"
        disabled={readOnly}
        {...register(`${date}.quantity`, {
          setValueAs: value =>
            value === '' || value === null ? null : Number(value),
          required: false,
          validate: numericValidation,
        })}
      />
      <InputField
        id={`productDominance_${date}`}
        type="text"
        autoComplete="off"
        disabled={readOnly}
        {...register(`${date}.productDominance`, {
          setValueAs: value =>
            value === '' || value === null ? null : Number(value),
          required: false,
          validate: numericValidation,
        })}
      />
      <SkapaSelect
        id={`${date}.merchandisingSolution`}
        {...register(`${date}.merchandisingSolution`)}
        disabled={readOnly}
      >
        {Object.values(MerchandisingSolution).map(m => (
          <option value={m} key={m}>
            {merchText(m)}
          </option>
        ))}
      </SkapaSelect>
      <Checkbox
        id={`homebase_${date}`}
        value={''}
        disabled={readOnly}
        {...register(`${date}.homebase`)}
      />
      <Checkbox
        id={`primary_${date}`}
        value={''}
        disabled={readOnly}
        {...register(`${date}.primary`)}
      />
      <Checkbox
        id={`manualPick_${date}`}
        value={''}
        disabled={readOnly}
        {...register(`${date}.manualPick`)}
      />
    </div>
  );
};

function comparisonCopy(values: FormValues): FormValues {
  // Quick-and-dirty deep copy using JSON serialization.
  const copy = { ...JSON.parse(JSON.stringify(values)) };
  for (const key in copy) {
    delete copy[key].isModified;
  }
  return copy;
}

export type WeekAssignmentsModalProps = {
  value: WeekAssignments;
  hideModal: HideModal;
  onConfirm: (assignments: DayAssignment[]) => Promise<void>;
};

export const WeekAssignmentsModal: React.FC<WeekAssignmentsModalProps> = ({
  value,
  hideModal,
  onConfirm,
}) => {
  const {
    assignments: assignmentsRaw,
    slid,
    weekNumber,
    recommendedMerchandising,
  } = value;
  const assignments = useMemo(() => {
    return assignmentsRaw.map(da =>
      !da.isDeleted
        ? da
        : {
            ...da,
            quantity: null,
            productDominance: null,
            merchandisingSolution:
              recommendedMerchandising || MerchandisingSolution.F_NotRTS,
            homebase: false,
            primary: false,
            manualPick: false,
          }
    );
  }, [assignmentsRaw, recommendedMerchandising]);

  const { isModalVisible, handleOk, onCancel, onClose, onModalClosed } =
    useDialogActions<FormValues>({
      hideModal,
      onOutcome: async values => {
        if (values) {
          const assignments: DayAssignment[] = Object.entries(values).map(
            ([_, value]) => value
          );
          await onConfirm(assignments);
        }
      },
    });

  const defaultValues = useMemo<FormValues>(() => {
    return Object.fromEntries(assignments.map(x => [x.fromDate, x]));
  }, [assignments]);

  const { register, handleSubmit, formState, watch, getValues, setValue } =
    useForm({
      mode: 'onChange',
      defaultValues,
    });

  // Synchronize
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') {
        return;
      }

      const activeDate = name.substring(0, name.indexOf('.'));
      setValue(`${activeDate}.isExplicit`, true);
      setValue(`${activeDate}.isModified`, true);
      const values = getValues(`${activeDate}`) as DayAssignment;

      // Update form values for remaining week days
      const dates = Object.keys(getValues()).sort();
      for (let i = dates.indexOf(activeDate) + 1; i < 7; i++) {
        const date = dates[i];
        if (getValues(`${date}.isExplicit`) === true) {
          // Stop updating once we encounter an explicit assignment.
          break;
        }

        const {
          quantity,
          productDominance,
          merchandisingSolution,
          homebase,
          primary,
          manualPick,
        } = values;
        setValue(
          `${date}.quantity`,
          quantity === null
            ? null
            : typeof quantity === 'number' && !Number.isNaN(quantity)
            ? quantity
            : 0
        );
        setValue(
          `${date}.productDominance`,
          productDominance === null
            ? null
            : typeof productDominance === 'number' &&
              !Number.isNaN(productDominance)
            ? productDominance
            : 0
        );
        setValue(`${date}.merchandisingSolution`, merchandisingSolution);
        setValue(`${date}.homebase`, homebase);
        setValue(`${date}.primary`, primary);
        setValue(`${date}.manualPick`, manualPick);
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, watch, setValue]);

  const onValid = (values: FormValues) => {
    const diff = updatedDiff(
      comparisonCopy(defaultValues),
      comparisonCopy(values)
    );
    const keys = Object.keys(diff);
    const entries = Object.entries(values).filter(
      ([key, value]) => keys.indexOf(key) !== -1 && value.isExplicit
    );
    values = Object.fromEntries(entries);
    handleOk(values);
  };

  const { isDirty, isValid } = formState;

  const { $t } = useIntl();
  const title = `${slid} ${$t(cm.weekPrefix)}${weekNumber}`;

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Modal
        visible={isModalVisible}
        handleCloseBtn={onClose}
        onModalClosed={onModalClosed}
      >
        <Prompt
          className="slm-prompt slm-ok-cancel slm-week-assignments"
          title={title}
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                text={$t(cm.ok)}
                htmlType="submit"
                disabled={!(isDirty && isValid)}
              />
              <Button text={$t(cm.cancel)} onClick={onCancel} />
            </ModalFooter>
          }
        >
          <div className="slm-container">
            <div className="slm-headings">
              <span />
              <label>{$t(mm.maintainAssignmentQuantity)}</label>
              <label>{$t(mm.maintainAssignmentProductDominance)}</label>
              <label>{$t(mm.maintainAssignmentMerchandisingSolution)}</label>
              <label>{$t(mm.maintainAssignmentHomebase)}</label>
              <label>{$t(mm.maintainAssignmentPrimary)}</label>
              <label>{$t(mm.maintainAssignmentManualPick)}</label>
            </div>
            {assignments.map((assignment, index) => (
              <DayAssignmentColumn
                key={index}
                watch={watch}
                register={register}
                date={assignment.fromDate}
                readOnly={!assignment.isEditable}
              />
            ))}
          </div>
        </Prompt>
      </Modal>
    </form>
  );
};
