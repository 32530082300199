/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible Problem codes
 * @export
 */
export const SearchProblemCodeEnum = {
    NEW_PROBLEM: '1',
    EXISTING_PROBLEM: '2',
    SOLVED: '3'
} as const;
export type SearchProblemCodeEnum = typeof SearchProblemCodeEnum[keyof typeof SearchProblemCodeEnum];


export function instanceOfSearchProblemCodeEnum(value: any): boolean {
    for (const key in SearchProblemCodeEnum) {
        if (Object.prototype.hasOwnProperty.call(SearchProblemCodeEnum, key)) {
            if (SearchProblemCodeEnum[key as keyof typeof SearchProblemCodeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchProblemCodeEnumFromJSON(json: any): SearchProblemCodeEnum {
    return SearchProblemCodeEnumFromJSONTyped(json, false);
}

export function SearchProblemCodeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchProblemCodeEnum {
    return json as SearchProblemCodeEnum;
}

export function SearchProblemCodeEnumToJSON(value?: SearchProblemCodeEnum | null): any {
    return value as any;
}

export function SearchProblemCodeEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): SearchProblemCodeEnum {
    return value as SearchProblemCodeEnum;
}

