/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Defines column names used in the getSnapshotView report. Due to lack of codegen support for 'oneOf', this type cannot be referenced directly.
 * @export
 */
export const CommercialEfficiencyColumn = {
    DirectFlowPercentage: 'directFlowPercentage',
    RssqFfPercentage: 'rssqFfPercentage',
    MovesSoldM3: 'movesSoldM3',
    Unplanned: 'unplanned',
    UnderMinSsq: 'underMinSsq',
    OverMaxSsq: 'overMaxSsq',
    NumLocalLocal: 'numLocalLocal',
    Overstock: 'overstock',
    LimitedSupply: 'limitedSupply',
    NoSupply: 'noSupply',
    RtsImpl: 'rtsImpl',
    FlexAvg: 'flexAvg',
    FlexShare: 'flexShare',
    ExpNetTurnRev: 'expNetTurnRev',
    ExpNetTurnRevM2: 'expNetTurnRevM2'
} as const;
export type CommercialEfficiencyColumn = typeof CommercialEfficiencyColumn[keyof typeof CommercialEfficiencyColumn];


export function instanceOfCommercialEfficiencyColumn(value: any): boolean {
    for (const key in CommercialEfficiencyColumn) {
        if (Object.prototype.hasOwnProperty.call(CommercialEfficiencyColumn, key)) {
            if (CommercialEfficiencyColumn[key as keyof typeof CommercialEfficiencyColumn] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CommercialEfficiencyColumnFromJSON(json: any): CommercialEfficiencyColumn {
    return CommercialEfficiencyColumnFromJSONTyped(json, false);
}

export function CommercialEfficiencyColumnFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommercialEfficiencyColumn {
    return json as CommercialEfficiencyColumn;
}

export function CommercialEfficiencyColumnToJSON(value?: CommercialEfficiencyColumn | null): any {
    return value as any;
}

export function CommercialEfficiencyColumnToJSONTyped(value: any, ignoreDiscriminator: boolean): CommercialEfficiencyColumn {
    return value as CommercialEfficiencyColumn;
}

