/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible Article Status values
 * @export
 */
export const SearchArticleStatusEnum = {
    PLANNED: 'planned',
    UNPLANNED: 'unplanned',
    UPCOMING_CHANGES: 'upcoming',
    NO_SALES_LOCATION: 'noSlid',
    ASSQ_LESS_THAN_RSSQ: 'assqLtRssq'
} as const;
export type SearchArticleStatusEnum = typeof SearchArticleStatusEnum[keyof typeof SearchArticleStatusEnum];


export function instanceOfSearchArticleStatusEnum(value: any): boolean {
    for (const key in SearchArticleStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(SearchArticleStatusEnum, key)) {
            if (SearchArticleStatusEnum[key as keyof typeof SearchArticleStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchArticleStatusEnumFromJSON(json: any): SearchArticleStatusEnum {
    return SearchArticleStatusEnumFromJSONTyped(json, false);
}

export function SearchArticleStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchArticleStatusEnum {
    return json as SearchArticleStatusEnum;
}

export function SearchArticleStatusEnumToJSON(value?: SearchArticleStatusEnum | null): any {
    return value as any;
}

export function SearchArticleStatusEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): SearchArticleStatusEnum {
    return value as SearchArticleStatusEnum;
}

