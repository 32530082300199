/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UnassignLocationConflict = {
    INTERNAL_ORDER: 'internalOrder'
} as const;
export type UnassignLocationConflict = typeof UnassignLocationConflict[keyof typeof UnassignLocationConflict];


export function instanceOfUnassignLocationConflict(value: any): boolean {
    for (const key in UnassignLocationConflict) {
        if (Object.prototype.hasOwnProperty.call(UnassignLocationConflict, key)) {
            if (UnassignLocationConflict[key as keyof typeof UnassignLocationConflict] === value) {
                return true;
            }
        }
    }
    return false;
}

export function UnassignLocationConflictFromJSON(json: any): UnassignLocationConflict {
    return UnassignLocationConflictFromJSONTyped(json, false);
}

export function UnassignLocationConflictFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnassignLocationConflict {
    return json as UnassignLocationConflict;
}

export function UnassignLocationConflictToJSON(value?: UnassignLocationConflict | null): any {
    return value as any;
}

export function UnassignLocationConflictToJSONTyped(value: any, ignoreDiscriminator: boolean): UnassignLocationConflict {
    return value as UnassignLocationConflict;
}

