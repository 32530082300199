/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ItemParameterDto } from './ItemParameterDto';
import {
    ItemParameterDtoFromJSON,
    ItemParameterDtoFromJSONTyped,
    ItemParameterDtoToJSON,
    ItemParameterDtoToJSONTyped,
} from './ItemParameterDto';

/**
 * 
 * @export
 * @interface PaginatedItemParameterDto
 */
export interface PaginatedItemParameterDto {
    /**
     * Number of the requested page, first page is pageNr='1'
     * @type {number}
     * @memberof PaginatedItemParameterDto
     */
    pageNr?: number;
    /**
     * Size of the requested page
     * @type {number}
     * @memberof PaginatedItemParameterDto
     */
    pageSize?: number;
    /**
     * Total number of content elements over all pages
     * @type {number}
     * @memberof PaginatedItemParameterDto
     */
    totalElements?: number;
    /**
     * List of content elements in this page
     * @type {Array<ItemParameterDto>}
     * @memberof PaginatedItemParameterDto
     */
    content?: Array<ItemParameterDto>;
}

/**
 * Check if a given object implements the PaginatedItemParameterDto interface.
 */
export function instanceOfPaginatedItemParameterDto(value: object): value is PaginatedItemParameterDto {
    return true;
}

export function PaginatedItemParameterDtoFromJSON(json: any): PaginatedItemParameterDto {
    return PaginatedItemParameterDtoFromJSONTyped(json, false);
}

export function PaginatedItemParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedItemParameterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'pageNr': json['pageNr'] == null ? undefined : json['pageNr'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
        'totalElements': json['totalElements'] == null ? undefined : json['totalElements'],
        'content': json['content'] == null ? undefined : ((json['content'] as Array<any>).map(ItemParameterDtoFromJSON)),
    };
}

export function PaginatedItemParameterDtoToJSON(json: any): PaginatedItemParameterDto {
    return PaginatedItemParameterDtoToJSONTyped(json, false);
}

export function PaginatedItemParameterDtoToJSONTyped(value?: PaginatedItemParameterDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'pageNr': value['pageNr'],
        'pageSize': value['pageSize'],
        'totalElements': value['totalElements'],
        'content': value['content'] == null ? undefined : ((value['content'] as Array<any>).map(ItemParameterDtoToJSON)),
    };
}

