import React, { useEffect, useState } from 'react';
import { useGetMetadataQuery } from 'core/redux/storeMetadataApi';
import Button from '@ingka/button';
import { useConfirmDialog } from 'components/Modal/Dialog';
import { usePostItemAssignmentMigrationMutation } from 'views/DevTools/redux/utilityApi';
import { useRouteBuCode } from 'hooks/buCode';

const AssignmentMigration: React.FC = () => {
  const [confirmed, setConfirmed] = useState(false);
  const buCode = useRouteBuCode();
  const showConfirmDialog = useConfirmDialog({
    title: 'Migrate Assignments',
    body: 'Start migration?',
    onOutcome: confirmed => {
      if (confirmed) {
        setConfirmed(true);
      }
    },
  });

  const [trigger, { isSuccess }] = usePostItemAssignmentMigrationMutation();

  useEffect(() => {
    if (confirmed) {
      trigger();
    }
  }, [confirmed, trigger]);

  const { data } = useGetMetadataQuery(buCode);
  const { isCloudMaster } = data ?? {};

  return (
    <>
      <h2>Assignment Migration</h2>
      {isCloudMaster && !confirmed && (
        <>
          <Button onClick={showConfirmDialog}>Start migration</Button>
          <p>The migration will run in the background.</p>
        </>
      )}
      {isCloudMaster && isSuccess && <p>Migration started. Have a good one!</p>}
      {isCloudMaster === false && (
        <p>Assignment migration is only available for Cloud stores</p>
      )}
    </>
  );
};

export default AssignmentMigration;
