/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The possible action types and combinations to filter on.
 * @export
 */
export const SearchActionFilterEnum = {
    ALERTS_ALL: 'alertsAll',
    ALERTS_ASSQ_LESS_THAN_MIN: 'alertsAssqLessThanMin',
    ALERTS_ASSQ_BIGGER_THAN_MAX: 'alertsAssqBiggerThanMax',
    ALERTS_UNPLANNED: 'alertsUnplanned',
    ALERTS_LOCAL_LOCAL_SHORTAGE: 'alertsLocalLocalShortage',
    NOTIFICATIONS_ALL: 'notificationsAll',
    NOTIFICATIONS_AND_ALERTS: 'notificationsAndAlerts',
    WARNINGS_ALL: 'warningsAll',
    WARNINGS_ASSQ_LESS_THAN_MIN: 'warningsAssqLessThanMin',
    WARNINGS_ASSQ_BIGGER_THAN_MAX: 'warningsAssqBiggerThanMax',
    WARNINGS_UNPLANNED: 'warningsUnplanned'
} as const;
export type SearchActionFilterEnum = typeof SearchActionFilterEnum[keyof typeof SearchActionFilterEnum];


export function instanceOfSearchActionFilterEnum(value: any): boolean {
    for (const key in SearchActionFilterEnum) {
        if (Object.prototype.hasOwnProperty.call(SearchActionFilterEnum, key)) {
            if (SearchActionFilterEnum[key as keyof typeof SearchActionFilterEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchActionFilterEnumFromJSON(json: any): SearchActionFilterEnum {
    return SearchActionFilterEnumFromJSONTyped(json, false);
}

export function SearchActionFilterEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchActionFilterEnum {
    return json as SearchActionFilterEnum;
}

export function SearchActionFilterEnumToJSON(value?: SearchActionFilterEnum | null): any {
    return value as any;
}

export function SearchActionFilterEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): SearchActionFilterEnum {
    return value as SearchActionFilterEnum;
}

