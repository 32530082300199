/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RangeNodeDto
 */
export interface RangeNodeDto {
    /**
     * Name of the HFB
     * @type {string}
     * @memberof RangeNodeDto
     */
    name?: string;
    /**
     * Description of the HFB
     * @type {string}
     * @memberof RangeNodeDto
     */
    description?: string;
    /**
     * sub level - RA or PA
     * @type {Array<RangeNodeDto>}
     * @memberof RangeNodeDto
     */
    subNodes?: Array<RangeNodeDto>;
}

/**
 * Check if a given object implements the RangeNodeDto interface.
 */
export function instanceOfRangeNodeDto(value: object): value is RangeNodeDto {
    return true;
}

export function RangeNodeDtoFromJSON(json: any): RangeNodeDto {
    return RangeNodeDtoFromJSONTyped(json, false);
}

export function RangeNodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RangeNodeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'subNodes': json['subNodes'] == null ? undefined : ((json['subNodes'] as Array<any>).map(RangeNodeDtoFromJSON)),
    };
}

export function RangeNodeDtoToJSON(json: any): RangeNodeDto {
    return RangeNodeDtoToJSONTyped(json, false);
}

export function RangeNodeDtoToJSONTyped(value?: RangeNodeDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'description': value['description'],
        'subNodes': value['subNodes'] == null ? undefined : ((value['subNodes'] as Array<any>).map(RangeNodeDtoToJSON)),
    };
}

