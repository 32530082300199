import React, { Fragment, useCallback, useMemo } from 'react';
import {
  ArticleAssignmentStatus,
  AssignmentDto,
  ItemAssignmentsDto,
  ItemDetailsDto,
  MerchandisingSolution,
  WeekCalendar,
} from 'apis/backendApi';
import { useIntl } from 'react-intl';
import Tooltip from '@ingka/tooltip';
import mm, {
  itemDetailsTableLocationShortHandMessages,
  itemDetailsTableLocationTooltipMessages,
} from 'views/Maintain/maintainMessages';
import {
  UnassignLocation,
  useAddAssignLocationAction,
  useEditWeekAssignmentsAction,
  usePendingAssignments,
  useUnassignLocationAction,
} from 'views/Maintain/hooks/assignments';
import Button from '@ingka/button';
import { pencil, plus, trashCan } from '@ingka/ssr-icon/icons';
import cm from 'core/commonMessages';
import { TableBody } from '@ingka/table';
import { useLocationHierarchy } from 'views/Maintain/components/ItemDetails/LocationHierarchy';
import { useMerchandisingTerms } from 'views/Maintain/hooks/language';
import { useHasUpdateItemAssignedSalesLocationPrivilege } from 'hooks/privilege';

type TableCell = { value: string; tooltip: string; isDeleted: boolean };

type Location = {
  itemNo: string;
  slid: string;
  rangeId: string;
  qty: TableCell[];
  prodDom: TableCell[];
  merch: TableCell[];
  alloc: TableCell[];
  locationType: TableCell[];
  pickLocation: TableCell[];
  isDeleted: boolean[];
  planned: boolean;
  forecast: Array<Number>;
};

function singleCellRender(cell: TableCell, index: number) {
  return cell.isDeleted ? (
    <td key={index} className="slm-grey" />
  ) : (
    <td key={index}>
      <Tooltip tooltipText={cell.tooltip}>
        <span>{cell.value}</span>
      </Tooltip>
    </td>
  );
}

const AssignmentEditorCell: React.FC<{
  itemNo: string;
  slid: string;
  weekNumber: number;
  disabled: boolean;
  recommendedMerchandising: MerchandisingSolution;
  quantity?: number;
}> = ({ itemNo, slid, weekNumber, disabled, recommendedMerchandising }) => {
  const onEditWeekAssignments = useEditWeekAssignmentsAction(
    itemNo,
    slid,
    weekNumber,
    recommendedMerchandising
  );

  return (
    <Button
      onClick={onEditWeekAssignments}
      disabled={disabled}
      small
      ssrIcon={pencil}
      iconOnly
    />
  );
};

const LocationTableBody: React.FC<{
  location: Location;
  weeks: WeekCalendar[];
  unassignLocation: UnassignLocation;
  recommendedMerchandising: MerchandisingSolution;
}> = ({ location, weeks, unassignLocation, recommendedMerchandising }) => {
  const { itemNo, slid, rangeId, planned } = location;
  const pendingAssignments = usePendingAssignments(itemNo, slid);
  const hasPendingAssignments = pendingAssignments.length !== 0;

  const canUpdate = useHasUpdateItemAssignedSalesLocationPrivilege();

  const onUnassignClick = useCallback(() => {
    unassignLocation({ itemNo, slid, rangeId });
  }, [unassignLocation, itemNo, slid, rangeId]);
  const onAddLocation = useAddAssignLocationAction(itemNo, slid);

  const { $t } = useIntl();

  const hierarchy = useLocationHierarchy(rangeId, slid);

  return (
    <TableBody className="slm-location">
      <tr className="slm-spacing">
        <td colSpan={weeks.length + 3} />
      </tr>
      <tr>
        <th colSpan={2}>
          <Tooltip tooltipText={hierarchy}>
            <span>{slid}</span>
          </Tooltip>
        </th>
        {weeks.map(week => (
          <th key={week.weekNumber}>
            {$t(cm.weekPrefix)}
            {week.weekNumber}
          </th>
        ))}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(mm.maintainItemDetailsQuantity)}>
            <>{$t(mm.maintainItemDetailsQuantityTooltip)}</>
          </Tooltip>
        </th>
        <td />
        {location.qty.map(singleCellRender)}
      </tr>
      <tr>
        <th>
          <Tooltip
            tooltipText={$t(mm.maintainItemDetailsProductDominanceTooltip)}
          >
            <>{$t(mm.maintainItemDetailsProductDominance)}</>
          </Tooltip>
        </th>
        <td />
        {location.prodDom.map(singleCellRender)}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(mm.maintainItemDetailsMerchSolutionTooltip)}>
            <>{$t(mm.maintainItemDetailsMerchSolution)}</>
          </Tooltip>
        </th>
        <td />
        {location.merch.map(singleCellRender)}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(mm.maintainItemDetailsAllocRatioTooltip)}>
            <>{$t(mm.maintainItemDetailsAllocRatio)}</>
          </Tooltip>
        </th>
        <td />
        {location.alloc.map(singleCellRender)}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(mm.maintainItemDetailsLocationTypeTooltip)}>
            <>{$t(mm.maintainItemDetailsLocationType)}</>
          </Tooltip>
        </th>
        <td />
        {location.locationType.map(singleCellRender)}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(mm.maintainItemDetailsPickLocationTooltip)}>
            <>{$t(mm.maintainItemDetailsPickLocation)}</>
          </Tooltip>
        </th>
        <td />
        {location.pickLocation.map(singleCellRender)}
      </tr>
      {canUpdate && planned && (
        <tr>
          <td colSpan={2}>
            <Button
              small
              ssrIcon={trashCan}
              iconOnly
              onClick={onUnassignClick}
              disabled={hasPendingAssignments}
            />
          </td>
          {weeks.map((week, weekIndex) => (
            <td key={week.weekNumber}>
              <AssignmentEditorCell
                itemNo={itemNo}
                slid={slid}
                weekNumber={week.weekNumber}
                recommendedMerchandising={recommendedMerchandising}
                disabled={
                  hasPendingAssignments ||
                  (location.isDeleted[weekIndex] &&
                    !(location.forecast[weekIndex] > 0)) // Allow edit if forecast exists
                }
              />
            </td>
          ))}
        </tr>
      )}
      {canUpdate && !planned && (
        <tr>
          <td>
            <Button
              small
              ssrIcon={plus}
              iconOnly
              onClick={onAddLocation}
              disabled={hasPendingAssignments}
            />
          </td>
        </tr>
      )}
    </TableBody>
  );
};

export type AssignmentTableBodyProps = {
  data: ItemDetailsDto;
  assignments: ItemAssignmentsDto;
};

const REMOVED_CELL: TableCell = {
  value: null,
  tooltip: null,
  isDeleted: true,
};

const AssignmentTableBody: React.FC<AssignmentTableBodyProps> = ({
  data,
  assignments,
}) => {
  const { $t, formatNumber } = useIntl();
  const merchShort = useMerchandisingTerms('short');
  const merchTooltip = useMerchandisingTerms('full');

  const locations = useMemo(() => {
    const { itemNo, weeks, articleAssignmentStatus, recommendations } = data;

    const weeksWithValues: Array<boolean> = weeks.map((_, weekIndex) => {
      return assignments.locations.some(location =>
        location.assignments[weekIndex].some(day => day !== null)
      );
    });

    const forecast: Array<Number> = recommendations?.map(r => r?.forecast);

    return assignments?.locations.map(location => {
      let currentDto: AssignmentDto = null;
      const locationRow: Location = {
        itemNo,
        slid: location.salesLocation.id,
        rangeId: location.salesLocation.rangeGroup,
        locationType: new Array(weeks.length),
        pickLocation: new Array(weeks.length),
        alloc: new Array(weeks.length),
        merch: new Array(weeks.length),
        prodDom: new Array(weeks.length),
        qty: new Array(weeks.length),
        isDeleted: new Array(weeks.length),
        planned: articleAssignmentStatus === ArticleAssignmentStatus.PLANNED,
        forecast,
      };
      weeks.forEach((week, weekIndex) => {
        const lastWeekEntry = location.assignments[weekIndex]?.findLast(
          e => e !== null
        );
        if (lastWeekEntry) {
          currentDto = lastWeekEntry;
        }

        if (currentDto === null || currentDto.isDeleted) {
          locationRow.qty[weekIndex] = REMOVED_CELL;
          locationRow.prodDom[weekIndex] = REMOVED_CELL;
          locationRow.merch[weekIndex] = REMOVED_CELL;
          locationRow.alloc[weekIndex] = REMOVED_CELL;
          locationRow.locationType[weekIndex] = REMOVED_CELL;
          locationRow.pickLocation[weekIndex] = REMOVED_CELL;
          locationRow.isDeleted[weekIndex] = true;
          return;
        }
        const showValues = weeksWithValues[weekIndex];

        locationRow.qty[weekIndex] = {
          isDeleted: false,
          tooltip: formatNumber(currentDto.quantity),
          value: showValues ? formatNumber(currentDto.quantity) : '-',
        };

        locationRow.prodDom[weekIndex] = {
          isDeleted: false,
          tooltip: formatNumber(currentDto.productDominance),
          value: showValues ? formatNumber(currentDto.productDominance) : '-',
        };

        locationRow.merch[weekIndex] = {
          isDeleted: false,
          tooltip: merchTooltip(currentDto.merchandisingSolution),
          value: showValues
            ? merchShort(currentDto.merchandisingSolution)
            : '-',
        };

        locationRow.alloc[weekIndex] = {
          isDeleted: false,
          tooltip: String(currentDto.allocationRatio),
          value: showValues ? String(currentDto.allocationRatio) : '-',
        };

        locationRow.locationType[weekIndex] = {
          isDeleted: false,
          tooltip: itemDetailsTableLocationTooltipMessages[
            currentDto.locationType
          ]
            ? $t(
                itemDetailsTableLocationTooltipMessages[currentDto.locationType]
              )
            : currentDto.locationType,
          value: showValues
            ? itemDetailsTableLocationShortHandMessages[currentDto.locationType]
              ? $t(
                  itemDetailsTableLocationShortHandMessages[
                    currentDto.locationType
                  ]
                )
              : currentDto.locationType
            : '-',
        };

        const label = currentDto.autoPick
          ? 'A'
          : currentDto.manualPick
          ? 'M'
          : 'N';
        locationRow.pickLocation[weekIndex] = {
          isDeleted: false,
          tooltip: label,
          value: showValues ? label : '-',
        };
      });
      return locationRow;
    });
  }, [$t, assignments, data, formatNumber, merchShort, merchTooltip]);

  const unassignLocation = useUnassignLocationAction();

  return (
    <>
      {locations?.map(location => {
        return (
          <LocationTableBody
            key={location.slid}
            location={location}
            weeks={data.weeks}
            unassignLocation={unassignLocation}
            recommendedMerchandising={data.recommendedMerchandisingSolution}
          />
        );
      })}
    </>
  );
};

export default AssignmentTableBody;
