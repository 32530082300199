/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BooleanEnum = {
    True: 'true',
    False: 'false',
    Empty: 'empty'
} as const;
export type BooleanEnum = typeof BooleanEnum[keyof typeof BooleanEnum];


export function instanceOfBooleanEnum(value: any): boolean {
    for (const key in BooleanEnum) {
        if (Object.prototype.hasOwnProperty.call(BooleanEnum, key)) {
            if (BooleanEnum[key as keyof typeof BooleanEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BooleanEnumFromJSON(json: any): BooleanEnum {
    return BooleanEnumFromJSONTyped(json, false);
}

export function BooleanEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanEnum {
    return json as BooleanEnum;
}

export function BooleanEnumToJSON(value?: BooleanEnum | null): any {
    return value as any;
}

export function BooleanEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): BooleanEnum {
    return value as BooleanEnum;
}

