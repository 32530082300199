/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LocationType = {
    NORMAL: '0',
    PRIMARY: '1',
    HOMEBASE: '2',
    HOMEBASE_PRIMARY: '3'
} as const;
export type LocationType = typeof LocationType[keyof typeof LocationType];


export function instanceOfLocationType(value: any): boolean {
    for (const key in LocationType) {
        if (Object.prototype.hasOwnProperty.call(LocationType, key)) {
            if (LocationType[key as keyof typeof LocationType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LocationTypeFromJSON(json: any): LocationType {
    return LocationTypeFromJSONTyped(json, false);
}

export function LocationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationType {
    return json as LocationType;
}

export function LocationTypeToJSON(value?: LocationType | null): any {
    return value as any;
}

export function LocationTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): LocationType {
    return value as LocationType;
}

