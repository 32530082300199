/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DivisionParameterDto } from './DivisionParameterDto';
import {
    DivisionParameterDtoFromJSON,
    DivisionParameterDtoFromJSONTyped,
    DivisionParameterDtoToJSON,
    DivisionParameterDtoToJSONTyped,
} from './DivisionParameterDto';
import type { HfbParameterDto } from './HfbParameterDto';
import {
    HfbParameterDtoFromJSON,
    HfbParameterDtoFromJSONTyped,
    HfbParameterDtoToJSON,
    HfbParameterDtoToJSONTyped,
} from './HfbParameterDto';
import type { ParameterDto } from './ParameterDto';
import {
    ParameterDtoFromJSON,
    ParameterDtoFromJSONTyped,
    ParameterDtoToJSON,
    ParameterDtoToJSONTyped,
} from './ParameterDto';

/**
 * Parameters and their values on store, division and HFB level
 * @export
 * @interface DefaultParameterDto
 */
export interface DefaultParameterDto {
    /**
     * Parameters and their values on store level
     * @type {Array<ParameterDto>}
     * @memberof DefaultParameterDto
     */
    storeDefaults?: Array<ParameterDto>;
    /**
     * Parameters and their values on division level
     * @type {Array<DivisionParameterDto>}
     * @memberof DefaultParameterDto
     */
    divisions?: Array<DivisionParameterDto>;
    /**
     * Parameters and their values on HFB level
     * @type {Array<HfbParameterDto>}
     * @memberof DefaultParameterDto
     */
    hfb?: Array<HfbParameterDto>;
}

/**
 * Check if a given object implements the DefaultParameterDto interface.
 */
export function instanceOfDefaultParameterDto(value: object): value is DefaultParameterDto {
    return true;
}

export function DefaultParameterDtoFromJSON(json: any): DefaultParameterDto {
    return DefaultParameterDtoFromJSONTyped(json, false);
}

export function DefaultParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultParameterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'storeDefaults': json['storeDefaults'] == null ? undefined : ((json['storeDefaults'] as Array<any>).map(ParameterDtoFromJSON)),
        'divisions': json['divisions'] == null ? undefined : ((json['divisions'] as Array<any>).map(DivisionParameterDtoFromJSON)),
        'hfb': json['hfb'] == null ? undefined : ((json['hfb'] as Array<any>).map(HfbParameterDtoFromJSON)),
    };
}

export function DefaultParameterDtoToJSON(json: any): DefaultParameterDto {
    return DefaultParameterDtoToJSONTyped(json, false);
}

export function DefaultParameterDtoToJSONTyped(value?: DefaultParameterDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'storeDefaults': value['storeDefaults'] == null ? undefined : ((value['storeDefaults'] as Array<any>).map(ParameterDtoToJSON)),
        'divisions': value['divisions'] == null ? undefined : ((value['divisions'] as Array<any>).map(DivisionParameterDtoToJSON)),
        'hfb': value['hfb'] == null ? undefined : ((value['hfb'] as Array<any>).map(HfbParameterDtoToJSON)),
    };
}

