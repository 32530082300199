/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Describes a single changes of a parameter to a new value
 * @export
 * @interface ParameterHistory
 */
export interface ParameterHistory {
    /**
     * Timestamp as string without timezone, of when the change was performed
     * @type {string}
     * @memberof ParameterHistory
     */
    timestamp?: string;
    /**
     * Id of the User that performed the change
     * @type {string}
     * @memberof ParameterHistory
     */
    user?: string;
    /**
     * Parameter Name
     * @type {string}
     * @memberof ParameterHistory
     */
    parameter?: string;
    /**
     * Old value of the parameter before the change, type bool, int or double
     * @type {object}
     * @memberof ParameterHistory
     */
    oldValue?: object;
    /**
     * New value of the parameter after the change, type bool, int or double
     * @type {object}
     * @memberof ParameterHistory
     */
    newValue?: object;
}

/**
 * Check if a given object implements the ParameterHistory interface.
 */
export function instanceOfParameterHistory(value: object): value is ParameterHistory {
    return true;
}

export function ParameterHistoryFromJSON(json: any): ParameterHistory {
    return ParameterHistoryFromJSONTyped(json, false);
}

export function ParameterHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'] == null ? undefined : json['timestamp'],
        'user': json['user'] == null ? undefined : json['user'],
        'parameter': json['parameter'] == null ? undefined : json['parameter'],
        'oldValue': json['oldValue'] == null ? undefined : json['oldValue'],
        'newValue': json['newValue'] == null ? undefined : json['newValue'],
    };
}

export function ParameterHistoryToJSON(json: any): ParameterHistory {
    return ParameterHistoryToJSONTyped(json, false);
}

export function ParameterHistoryToJSONTyped(value?: ParameterHistory | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'timestamp': value['timestamp'],
        'user': value['user'],
        'parameter': value['parameter'],
        'oldValue': value['oldValue'],
        'newValue': value['newValue'],
    };
}

