/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParameterDto
 */
export interface ParameterDto {
    /**
     * SLM Parameter ID
     * @type {string}
     * @memberof ParameterDto
     */
    name?: string;
    /**
     * Value of the parameter, one of Double, Integer, Boolean
     * @type {object}
     * @memberof ParameterDto
     */
    value?: object | null;
}

/**
 * Check if a given object implements the ParameterDto interface.
 */
export function instanceOfParameterDto(value: object): value is ParameterDto {
    return true;
}

export function ParameterDtoFromJSON(json: any): ParameterDto {
    return ParameterDtoFromJSONTyped(json, false);
}

export function ParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ParameterDtoToJSON(json: any): ParameterDto {
    return ParameterDtoToJSONTyped(json, false);
}

export function ParameterDtoToJSONTyped(value?: ParameterDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'value': value['value'],
    };
}

