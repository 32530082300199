import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';
import { StoreMetaDataDto } from 'apis/backendApi';

const storeMetadataApi = createApi({
  reducerPath: 'storeMetadataApi',
  baseQuery: apiQuery(),
  // serializeQueryArgs: apiSerializeQueryArgs,  // Don't use the standard apiSerialize here, since we want BU-code to be a regular input argument

  endpoints: build => ({
    getMetadata: build.query<StoreMetaDataDto, string>({
      query: () => {
        return {
          url: '/store/v1/{buCode}/metadata',
        };
      },
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const { useGetMetadataQuery } = storeMetadataApi;

export default storeMetadataApi;
