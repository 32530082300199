import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import withStoreStructure from 'components/WithStoreStructure';
import { useAppDispatch } from 'hooks/redux';
import {
  ImportStage,
  reset,
  retry,
  selectImportState,
} from 'views/Import/redux/importSlice';
import { useSelector } from 'react-redux';
import InlineMessage from '@ingka/inline-message';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';
import Button from '@ingka/button';
import withCloudOnly from 'components/WithCloudOnly';

const Import: React.FC = () => {
  const { stage, errorResponse } = useSelector(selectImportState);
  const navigate = useNavigate();
  const [currentStage, setCurrentStage] = useState<ImportStage>();
  useEffect(() => {
    // Guard against useEffect hook running twice in Development mode.
    if (stage === currentStage) {
      return;
    }
    setCurrentStage(stage);

    navigate(stage);
  }, [currentStage, navigate, stage]);

  const dispatch = useAppDispatch();
  useLayoutEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  const onReset = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  const onRetry = useCallback(() => {
    dispatch(retry());
  }, [dispatch]);

  const { $t } = useIntl();

  return (
    <>
      {!errorResponse && <Outlet />}
      {errorResponse && (
        <>
          <InlineMessage
            body={errorResponse.message}
            title={$t(cm.error)}
            variant="negative"
          />
          <p>
            <Button type="primary" text={$t(cm.retry)} onClick={onRetry} />{' '}
            <Button type="secondary" text={$t(cm.reset)} onClick={onReset} />
          </p>
        </>
      )}
    </>
  );
};

export default withStoreStructure(withCloudOnly(Import));
