/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterDto } from './ParameterDto';
import {
    ParameterDtoFromJSON,
    ParameterDtoFromJSONTyped,
    ParameterDtoToJSON,
    ParameterDtoToJSONTyped,
} from './ParameterDto';

/**
 * 
 * @export
 * @interface ItemParameterDto
 */
export interface ItemParameterDto {
    /**
     * Item number
     * @type {string}
     * @memberof ItemParameterDto
     */
    itemNo?: string;
    /**
     * Item description
     * @type {string}
     * @memberof ItemParameterDto
     */
    itemName?: string;
    /**
     * 
     * @type {Array<ParameterDto>}
     * @memberof ItemParameterDto
     */
    parameters?: Array<ParameterDto>;
}

/**
 * Check if a given object implements the ItemParameterDto interface.
 */
export function instanceOfItemParameterDto(value: object): value is ItemParameterDto {
    return true;
}

export function ItemParameterDtoFromJSON(json: any): ItemParameterDto {
    return ItemParameterDtoFromJSONTyped(json, false);
}

export function ItemParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemParameterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'itemNo': json['itemNo'] == null ? undefined : json['itemNo'],
        'itemName': json['itemName'] == null ? undefined : json['itemName'],
        'parameters': json['parameters'] == null ? undefined : ((json['parameters'] as Array<any>).map(ParameterDtoFromJSON)),
    };
}

export function ItemParameterDtoToJSON(json: any): ItemParameterDto {
    return ItemParameterDtoToJSONTyped(json, false);
}

export function ItemParameterDtoToJSONTyped(value?: ItemParameterDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'itemNo': value['itemNo'],
        'itemName': value['itemName'],
        'parameters': value['parameters'] == null ? undefined : ((value['parameters'] as Array<any>).map(ParameterDtoToJSON)),
    };
}

