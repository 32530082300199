import React, { ComponentType, FC, useEffect } from 'react';
import { useGetMetadataQuery } from 'core/redux/storeMetadataApi';
import { useRouteBuCode } from 'hooks/buCode';
import Loading, { LoadingBall } from '@ingka/loading';
import ForbiddenMessage from 'components/Message/ForbiddenMessage';
import { useNavigate } from 'react-router-dom';

export default function withCloudOnly<P extends object>(
  Component: ComponentType<P>
): FC<P> {
  return function (props: P) {
    const buCode = useRouteBuCode();
    const { data, isLoading, isError } = useGetMetadataQuery(buCode);
    const navigate = useNavigate();
    useEffect(() => {
      if (
        (data && !isLoading && !data.isCloudMaster) ||
        (!isLoading && isError)
      ) {
        navigate(`/store/${buCode}/maintain`);
      }
    }, [navigate, buCode, data, isLoading]);

    return (
      <>
        {isLoading && (
          <Loading>
            <LoadingBall />
          </Loading>
        )}
        {(!isLoading && data && data.isCloudMaster) ||
        (!isLoading && isError) ? (
          <Component {...props} />
        ) : (
          <ForbiddenMessage />
        )}
      </>
    );
  };
}
