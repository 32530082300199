import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import NotFoundMessage from 'components/Message/NotFoundMessage';
import Loading, { LoadingBall } from '@ingka/loading';
import { useGetStoresQuery } from 'core/redux/userApi';
import { useOptionalUser } from '../contexts/UserContext';
import { useRouteBuCode } from 'hooks/buCode';
import { setApiBuCode, setIsCloudApiEnabled } from 'core/apiConfig';
import { useGetMetadataQuery } from 'core/redux/storeMetadataApi';

export function useBuCodeIntegration() {
  const buCode = useRouteBuCode();

  const user = useOptionalUser();
  const { data, isLoading, isSuccess } = useGetStoresQuery({
    userId: user?.userId,
  });

  return useMemo(() => {
    const name = data?.storeNames[buCode];
    return {
      buCode,
      isLoading,
      isSuccess: isSuccess && name !== undefined,
      isAuthenticated: user !== null,
    };
  }, [buCode, data, isLoading, isSuccess, user]);
}

const StoreMetadataIntegration: React.FC<{
  buCode: string;
  children?: PropsWithChildren<any>;
}> = ({ buCode, children }) => {
  // Note: using 'skip' does not work, because this hook relies on the API BU code being configured first.
  const { data } = useGetMetadataQuery(buCode);
  setIsCloudApiEnabled(data?.isCloudMaster ?? false);

  return children;
};

const BuCodeIntegration: React.FC = () => {
  const { buCode, isSuccess, isLoading, isAuthenticated } =
    useBuCodeIntegration();

  if (!buCode) {
    throw new Error('This component must be used within a buCode route');
  }

  if (!isLoading && isSuccess) {
    setApiBuCode(buCode);
  } else {
    setApiBuCode(null);
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate('/');
    }
  }, [isLoading, isAuthenticated, navigate]);

  return (
    <>
      {isLoading && (
        <Loading>
          <LoadingBall />
        </Loading>
      )}
      {!isLoading && isSuccess && (
        <StoreMetadataIntegration buCode={buCode}>
          <Outlet />
        </StoreMetadataIntegration>
      )}
      {!isLoading && !isSuccess && <NotFoundMessage />}
    </>
  );
};

export default BuCodeIntegration;
